const translation = {
  en: {
    translation: {
      title: "Suiview",
      subtitle: "beta -Teacher mode-",
      changeLanguage: "Change Language",
      japanese: "Japanese",
      english: "English",
      home: "Home",
      logout: "Logout",
      students: "Students",
      enterStudentId: "Please enter a student ID.",
      addStudent: "Add student",
      folders: "Folders",
      songs: "Songs",
      userName: "Username",
      save: "Save",
      setting: "Settings",
      shareLine: "Share by LINE",
      changeUserName: "Change username",
      enterNewUserName: "Please enter a new username.",
      pitch: "Pitch",
      intensity: "Intensity",
      timber: "Timber",
      overallScore: "Total score",
      stackedScore: "Stacked score",
      noData: "There is no data.",
      letWriteComment: "Let's write comment from",
      writeFromBottomRight: "at the bottom right.",
      changeSelfAssessment: "Change self assessment.",
      writeComment: "Let's write comment",
      enterComment: "Please enter a comment.",
      transmissionIsComplete: "Transmission is complete",
      sendComment: "Send a comment",
      welcomSuiview: "Welcom to Suiview",
      homeScreen: "Home screen",
      login: "Login",
      max10: "At most the latest 10 data will be displayed",
      decibelValue: "Decibel value",
      frequency: "Frequency",
      seconds: "Seconds",
      homeP1: "Let's select student and folder.",
      homeP2:
        "If you select only students and folders, a graph of the accumulated data of that folder will be displayed.",
      homeP3:
        "If you select a song, you can see the graph of the sound source.",
      aboutSuiview: "About Suiview",
      loginPage1:
        "This application helps beginners who are just starting to practice wind instruments to produce stable sounds.",
      loginPage2:
        "You can easily record and visually check the stability of your performance from the three perspectives of pitch, intensity, and timbre.",
      whatYouCanDo: "What you can do in teacher mode",
      loginPage3:
        "In teacher mode, you can see and comment on student performances and graphs.Students can see the comments you write.",
      time: "Time",
    },
  },
  ja: {
    translation: {
      title: "吹view",
      subtitle: "β版-先生モード-",
      changeLanguage: "言語設定",
      japanese: "日本語",
      english: "英語",
      home: "ホーム",
      logout: "ログアウト",
      students: "生徒",
      enterStudentId: "生徒のIDを入力してください",
      addStudent: "生徒の追加",
      folders: "フォルダー",
      songs: "録音データ",
      userName: "ユーザー名",
      save: "保存",
      setting: "設定",
      changeUserName: "ユーザー名の変更",
      enterNewUserName: "新しいユーザー名を入力してください",
      pitch: "高さ",
      intensity: "強さ",
      timber: "音色",
      overallScore: "精進グラフ",
      stackedScore: "総合点",
      noData: "データがありません",
      writeComment: "コメントを記入してください",
      enterComment: "コメントを入力してください",
      sendComment: "コメントを送る",
      transmissionIsComplete: "送信が完了しました",
      login: "ログイン",
      max10: "最大直近10個のデータです",
      decibelValue: "デシベル値",
      frequency: "周波数",
      seconds: "秒",
      homeP1: "生徒とフォルダーを選択しましょう。",
      homeP2:
        "生徒とフォルダーのみの選択ではそのフォルダの蓄積データのグラフが表示され、",
      homeP3:
        "さらに録音データを選択するとその音源のグラフを見ることができます。",
      aboutSuiview: "吹viewとは",
      loginPage1:
        " 管楽器の練習を始めたばかりの初心者が安定した音を出せるようにするためのお手伝いをするアプリです。",
      loginPage2:
        "管楽器を練習したいユーザが気軽に録音し,高さ・強さ・音色の3観点からどの程度演奏が安定しているかを視覚的に確かめることができます。",
      whatYouCanDo: "先生モードでできること",
      loginPage3:
        " 先生モードでは生徒が演奏した音源とグラフを見てコメントを付けることができます。付けたコメントは生徒が見ることができます。",
      time: "時間",
    },
  },
};

export default translation;
